<template>
  <div class="flex items-center p-10 pb-3">
    <img :src="require('/assets/images/mydid-logo.png')" alt="" class="w-28" />
    <div class="grow"></div>
    <div v-if="isAdmin" class="mr-3 lg:mr-10">
      <router-link to="/admin"
        ><p class="flex items-center">
          <i class="fas fa-user-shield mr-2"></i
          ><span class="hidden lg:block">Admin</span>
        </p></router-link
      >
    </div>
    <div v-if="user" class="mr-3 lg:mr-10">
      <router-link to="/user"
        ><p class="flex">
          <icon name="account" class="w-5 fill-black mr-1.5" /><span
            class="hidden lg:block"
            >Compte</span
          >
        </p></router-link
      >
    </div>
    <div v-if="user" class="mr-3 lg:mr-10">
      <p @click="logout" class="flex cursor-pointer">
        <icon name="logout" class="w-4 fill-black mr-2" /><span
          class="hidden lg:block"
          >Déconnexion</span
        >
      </p>
    </div>
    <div class="mr-3 lg:mr-10">
      <a href="https://support.balota.fr/" target="_blank"
        ><p class="flex">
          <icon name="help" class="w-5 fill-black mr-2" /><span
            class="hidden lg:block"
            >Aide</span
          >
        </p></a
      >
    </div>
    <div class="hidden lg:block text-xl font-bold">Demo</div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  props: {},
  setup(props, context) {
    const $api = inject('$api');
    const $cookies = inject('$cookies');
    const user = ref(null);
    const isAdmin = ref(false);

    if ($cookies.get('token')) {
      $api.getUser().then((res) => {
        user.value = res.data;
      });

      $api.isAdmin().then((res) => {
        isAdmin.value = res.data.isAdmin;
      });
    }

    function logout() {
      $cookies.remove('token');
      window.location.replace('/');
    }

    return { user, isAdmin, logout };
  },
};
</script>
